import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _omit2 from "../run/omit";
import _flatten2 from "../run/flatten";
import _empty2 from "../attributedString/empty";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _omit = _interopRequireDefault(_omit2);

var _flatten = _interopRequireDefault(_flatten2);

var _empty = _interopRequireDefault(_empty2);

var omitFont = R.evolve({
  runs: R.map((0, _omit.default)("font"))
});
/**
 * Performs font substitution and script itemization on attributed string
 *
 * @param  {Object}  engines
 * @param  {Object}  layout options
 * @param  {Object}  attributed string
 * @return {Object} processed attributed string
 */

var preprocessRuns = function preprocessRuns(engines, options) {
  return R.ifElse(R.isNil, _empty.default, R.applySpec({
    string: R.prop("string"),
    runs: R.compose(_flatten.default, R.flatten, R.pluck("runs"), R.juxt([engines.fontSubstitution(options), // font substitution
    engines.scriptItemizer(options), // script itemization
    omitFont]))
  }));
};

var _default = preprocessRuns;
exports.default = _default;
export default exports;