import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _copy2 from "../rect/copy";
import _crop2 from "../rect/crop";
import _slice2 from "../block/slice";
import _height2 from "../block/height";
import _truncate2 from "../block/truncate";
import _layoutParagraph2 from "./layoutParagraph";
import _sliceAtHeight2 from "../block/sliceAtHeight";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _copy = _interopRequireDefault(_copy2);

var _crop = _interopRequireDefault(_crop2);

var _slice = _interopRequireDefault(_slice2);

var _height = _interopRequireDefault(_height2);

var _truncate = _interopRequireDefault(_truncate2);

var _layoutParagraph = _interopRequireDefault(_layoutParagraph2);

var _sliceAtHeight = _interopRequireDefault(_sliceAtHeight2);
/**
 * Layout paragraphs inside container until it does not
 * fit anymore, performing line wrapping in the process.
 *
 * @param  {Object}  engines
 * @param  {Object}  layout options
 * @param  {Object}  container rect
 * @param  {Object}  attributed strings (paragraphs)
 * @return {Array} paragraph blocks
 */


var typesetter = function typesetter(engines, options, container, attributedStrings) {
  var blocks = [];
  var paragraphs = [].concat(attributedStrings);
  var layoutBlock = (0, _layoutParagraph.default)(engines, options);
  var maxLines = R.propOr(Infinity, "maxLines", container);
  var truncateEllipsis = container.truncateMode === "ellipsis";
  var linesCount = maxLines;
  var paragraphRect = (0, _copy.default)(container);
  var nextParagraph = paragraphs.shift();

  while (linesCount > 0 && nextParagraph) {
    var block = layoutBlock(paragraphRect, nextParagraph);
    var slicedBlock = (0, _slice.default)(linesCount, block);
    var linesHeight = (0, _height.default)(slicedBlock);
    var shouldTruncate = truncateEllipsis && block.length !== slicedBlock.length;
    linesCount -= slicedBlock.length;

    if (paragraphRect.height >= linesHeight) {
      blocks.push(R.when(R.always(shouldTruncate), _truncate.default)(slicedBlock));
      paragraphRect = (0, _crop.default)(linesHeight, paragraphRect);
      nextParagraph = paragraphs.shift();
    } else {
      blocks.push(R.compose(_truncate.default, (0, _sliceAtHeight.default)(paragraphRect.height))(slicedBlock));
      break;
    }
  }

  return blocks;
};

var _default = R.curryN(4, typesetter);

exports.default = _default;
export default exports;