import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _wrapWords2 from "./wrapWords";
import _typesetter2 from "./typesetter";
import _generateGlyphs2 from "./generateGlyphs";
import _resolveYOffset2 from "./resolveYOffset";
import _preprocessRuns2 from "./preprocessRuns";
import _splitParagraphs2 from "./splitParagraphs";
import _finalizeFragments2 from "./finalizeFragments";
import _resolveAttachments2 from "./resolveAttachments";
import _applyDefaultStyles2 from "./applyDefaultStyles";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _wrapWords = _interopRequireDefault(_wrapWords2);

var _typesetter = _interopRequireDefault(_typesetter2);

var _generateGlyphs = _interopRequireDefault(_generateGlyphs2);

var _resolveYOffset = _interopRequireDefault(_resolveYOffset2);

var _preprocessRuns = _interopRequireDefault(_preprocessRuns2);

var _splitParagraphs = _interopRequireDefault(_splitParagraphs2);

var _finalizeFragments = _interopRequireDefault(_finalizeFragments2);

var _resolveAttachments = _interopRequireDefault(_resolveAttachments2);

var _applyDefaultStyles = _interopRequireDefault(_applyDefaultStyles2);
/**
 * A LayoutEngine is the main object that performs text layout.
 * It accepts an AttributedString and a Container object
 * to layout text into, and uses several helper objects to perform
 * various layout tasks. These objects can be overridden to customize
 * layout behavior.
 *
 * @param  {Object}  engines
 * @param  {Object}  attributed string
 * @param  {Object}  container rect
 * @param  {Object}  layout options
 * @return {Array} paragraph blocks
 */


var layoutEngine = function layoutEngine(engines, attributedString, container, options) {
  if (options === void 0) {
    options = {};
  }

  var processParagraphs = R.compose((0, _resolveYOffset.default)(engines, options), (0, _resolveAttachments.default)(engines, options), (0, _generateGlyphs.default)(engines, options), (0, _wrapWords.default)(engines, options));
  return R.compose((0, _finalizeFragments.default)(engines, options), (0, _typesetter.default)(engines, options, container), R.map(processParagraphs), (0, _splitParagraphs.default)(engines, options), (0, _preprocessRuns.default)(engines, options), (0, _applyDefaultStyles.default)(engines, options))(attributedString);
};

var _default = R.curryN(3, layoutEngine);

exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;