import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _extends3 from "@babel/runtime/helpers/extends";

var _extends = "default" in _extends3 ? _extends3.default : _extends3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _fromFragments2 from "../attributedString/fromFragments";
var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports.default = void 0;

var _extends2 = _interopRequireDefault(_extends);

var R = _interopRequireWildcard(_ramda);

var _fromFragments = _interopRequireDefault(_fromFragments2);
/**
 * Default word hyphenation engine used when no one provided.
 * Does not perform word hyphenation at all
 *
 * @param  {String} word
 * @return {Array} same word
 */


var defaultHyphenationEngine = function defaultHyphenationEngine(word) {
  return [word];
};
/**
 * Wrap words of attribute string
 *
 * @param  {Object} layout engines
 * @param  {Object}  layout options
 * @param  {Object}  attributed string
 * @return {Object} attributed string including syllables
 */


var wrapWords = function wrapWords(engines, options, attributedString) {
  if (engines === void 0) {
    engines = {};
  }

  if (options === void 0) {
    options = {};
  }

  var syllables = [];
  var fragments = [];
  var hyphenateWord = options.hyphenationCallback || engines.wordHyphenation && engines.wordHyphenation(options) || defaultHyphenationEngine;

  for (var i = 0; i < attributedString.runs.length; i += 1) {
    var string = "";
    var run = attributedString.runs[i];
    var words = attributedString.string.slice(run.start, run.end).split(/([ ]+)/g).filter(Boolean);

    for (var j = 0; j < words.length; j += 1) {
      var word = words[j];
      var parts = hyphenateWord(word);
      syllables.push.apply(syllables, parts);
      string += parts.join("");
    }

    fragments.push({
      string: string,
      attributes: run.attributes
    });
  }

  return (0, _extends2.default)({}, (0, _fromFragments.default)(fragments), {
    syllables: syllables
  });
};

var _default = R.curryN(3, wrapWords);

exports.default = _default;
export default exports;