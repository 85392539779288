import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);
/**
 * Get paragrpah block height
 *
 * @param  {Object}  paragraph block
 * @return {number} paragraph block height
 */


var height = R.compose(R.sum, R.map(R.prop("height")), R.pluck("box"));
var _default = height;
exports.default = _default;
export default exports;