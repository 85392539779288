import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);
/**
 * Crop upper section of rect
 *
 * @param  {Object}  rect
 * @return {Object} cropped rect
 */


var crop = function crop(height, rect) {
  return R.evolve({
    y: R.add(height),
    height: R.subtract(R.__, height)
  })(rect);
};

var _default = R.curryN(2, crop);

exports.default = _default;
export default exports;